
import './css/main.css';

import barba from '@barba/core';

import { gsap } from "gsap";

import { defineCustomElements } from 'vidstack/elements';


barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
      {
      name: 'opacity-transition',
      leave(data) {
        openPageChange(data);
        hamburgerToggle('c');
        return gsap.to(data.current.container, {
          opacity: 0
        });

        
      },
      enter(data) {
        
      },
      after(data) {
        return gsap.from(data.next.container, {
            opacity: 0
          });

        
        
        
      },
      once(data) {
        defineCustomElements();
        videos();
        
      },
      afterOnce(data) {
      }
    }],
});




  function openPageChange (data) {
    import(/* webpackChunkName: "menuHighlight" */ "./js/components/pageChange.js").then(module => {
      const changeGo = module.default;
      changeGo(data);
    });
  }

  function closePageChange() {
    let pc = document.getElementById('pageChanger');
    pc.classList.add('-translate-y-full');
  }



  function videos() {
    let vidPlayers = document.querySelectorAll('media-player');
    if (vidPlayers.length > 0) {
        let vd;
        for (vd=0; vd < vidPlayers.length; vd++) {
            vidPlayers[vd].addEventListener('provider-change', (event) => {
                const provider = event.detail;
                if (provider?.type === 'hls') {
                    console.log('HLS config change');
                    provider.config = { startLevel: 5 };
                };
            });
        };
    };
  };


  function hamburgerToggle(preset) {
    import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
      const toggle = module.default;
      toggle(preset);
    });
  }

  let hamburgerOuter = document.getElementById("hamburgerOuter");
  hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});

  function scrollToTop() {
    // window.scrollTo({top: 0, behavior: 'smooth'});
    window.scrollTo({top: 0});
  };

  barba.hooks.beforeEnter((data) => {
    scrollToTop();
    videos();
  });

  barba.hooks.after((data) => {
    defineCustomElements();
    
  });


  barba.hooks.afterEnter((data) => {
    setTimeout(function() {closePageChange();}, 500);
  });